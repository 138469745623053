import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { withFormik, Form, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import camelCaseRecursive from 'camelcase-keys-recursive';

import snakecaseKeys from 'snakecase-keys';

import validationSchema from './CargoAvailabilityFormValidations';

import {
  indexCargoAvailabilityRoutesItinerariesRequest,
  indexCargoAvailabilityItineraryAvailabilityRequest
} from '../../../requests/cargoAvailability';

import FirstStepCargoAvailabilityForm from './FirstStepCargoAvailabilityForm';
import SecondStepCargoAvailabilityForm from './SecondStepCargoAvailabilityForm';
import ThirdStepCargoAvailabilityForm from './ThirdStepCargoAvailabilityForm';
import { sendAlert } from '../../../actions/utils';

const CargoAvailabilityForm = ({
  errors,
  isSubmitting,
  setFieldTouched,
  setSubmitting,
  ...props
}) => {
  const { routes, ships } = props;
  const [availableShips, setAvailableShips] = useState([]);
  const [showShipsSelector, setShowShipsSelector] = useState(false);
  const [updateAvailableShips, setUpdateAvailableShips] = useState(false);
  const [availableItineraries, setAvailableItineraries] = useState([]);
  const [availableDecks, setAvailableDecks] = useState([]);
  const [gatherDecks, setGatherDecks] = useState(false);
  const [selectedPortText, setSelectedPortText] = useState('');
  const [selectedRouteText, setSelectedRouteText] = useState('');

  const [showStepTwo, setShowStepTwo] = useState(false);
  const [showStepThree, setShowStepThree] = useState(false);

  const modelName = 'cargoAvailability';

  const { values } = useFormikContext();
  const dispatch = useDispatch();
  const cancelToken = axios.CancelToken.source();

  const handleFailureRequest = error => {
    const errorMessage =
      error?.response?.data?.error?.message || 'Error al realizar la solicitud';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
  };

  const routesItineraryRequestWrapper = (params, dataHandler) => {
    indexCargoAvailabilityRoutesItinerariesRequest({
      dispatch,
      params,
      successCallback: result => {
        dataHandler({
          data: camelCaseRecursive(result.data),
          shipId: params.ship_id
        });
      },
      failureCallback: handleFailureRequest
    });
    return () => cancelToken.cancel();
  };

  const routesItineraryParamBuilder = (routeId, shipId) => {
    return snakecaseKeys({ routeId, shipId });
  };

  const dataHandler = ({ data, shipId }) => {
    if (data.length) {
      // update available ships
      const updatedAvailableShips = availableShips;
      updatedAvailableShips.push(ships.find(ship => ship.value === shipId));
      setAvailableShips(updatedAvailableShips);
      if (updatedAvailableShips.length > 0) setShowShipsSelector(true);
      // updateAvailable
      const updatedAvailableItineraries = availableItineraries;
      updatedAvailableItineraries.push({ [shipId]: data });
    }
  };

  const buildDeckParams = (routeId, itineraryId) => {
    return snakecaseKeys({ routeId, itineraryId });
  };

  const gatherDecksWrapper = () => {
    if (gatherDecks) {
      indexCargoAvailabilityItineraryAvailabilityRequest({
        dispatch,
        params: buildDeckParams(
          values.cargoAvailability.routeId,
          values.cargoAvailability.itineraryId
        ),
        successCallback: result => {
          setAvailableDecks(camelCaseRecursive(result.data.mliDisponiblesCubierta));
          setShowStepThree(true);
          setGatherDecks(false);
        },
        failureCallback: error => {
          setGatherDecks(false);
          handleFailureRequest(error);
        }
      });
    }
  };

  const updateAvailableShipsValues = () => {
    if (updateAvailableShips) {
      ships.forEach(ship => {
        const paramsToSend = routesItineraryParamBuilder(
          values?.cargoAvailability?.routeId,
          ship.value
        );
        routesItineraryRequestWrapper(paramsToSend, dataHandler);
      });

      setUpdateAvailableShips(false);
    }
  };

  useEffect(updateAvailableShipsValues, [updateAvailableShips]);
  useEffect(gatherDecksWrapper, [gatherDecks]);

  return (
    <Row className="justify-content-md-center">
      <Col md={8}>
        <Form>
          <Row className="mb-3">
            <Col>
              <Row>
                <Col className="align-middle cargo-availability-steps-map mb-4">
                  <Button className="cargo-availability-steps-map--step btn non-clickable filled mr-3">
                    1
                  </Button>
                  <p className="cargo-availability-form-title active">
                    Información del barco y ruta
                  </p>
                </Col>
              </Row>
              <Row className="ml-3 vertical-dashed-line">
                <Col className="ml-3">
                  <FirstStepCargoAvailabilityForm
                    modelName={modelName}
                    routes={routes}
                    ships={availableShips}
                    setUpdateAvailableShips={setUpdateAvailableShips}
                    setAvailableShips={setAvailableShips}
                    setAvailableItineraries={setAvailableItineraries}
                    setAvailableDecks={setAvailableDecks}
                    setShowStepTwo={setShowStepTwo}
                    setShowStepThree={setShowStepThree}
                    showShipsSelector={showShipsSelector}
                    setSelectedPortText={setSelectedPortText}
                    setSelectedRouteText={setSelectedRouteText}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Row>
                <Col className="align-middle cargo-availability-steps-map mb-4">
                  <Button
                    className={`cargo-availability-steps-map--step btn non-clickable mr-3 ${
                      showStepTwo ? 'filled' : ''
                    }`}
                  >
                    2
                  </Button>
                  <p
                    className={`cargo-availability-form-title ${
                      showStepTwo ? 'active' : ''
                    }`}
                  >
                    Zarpes disponibles
                  </p>
                </Col>
              </Row>
              <Row className="ml-3 vertical-dashed-line">
                <Col className="ml-3">
                  {showStepTwo && (
                    <SecondStepCargoAvailabilityForm
                      className="ml-3"
                      modelName={modelName}
                      availableItineraries={
                        availableItineraries?.find(
                          ship =>
                            String(Object.keys(ship)[0]) ===
                            String(values?.cargoAvailability?.shipId)
                        )[values?.cargoAvailability?.shipId]
                      }
                      selectedPortText={selectedPortText}
                      selectedRouteText={selectedRouteText}
                      setGatherDecks={setGatherDecks}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Row>
                <Col className="align-middle cargo-availability-steps-map mb-4">
                  <Button
                    className={`cargo-availability-steps-map--step btn non-clickable mr-3 ${
                      showStepThree ? 'filled' : ''
                    }`}
                  >
                    3
                  </Button>
                  <p
                    className={`cargo-availability-form-title ${
                      showStepThree ? 'active' : ''
                    }`}
                  >
                    Capacidades disponibles
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="ml-3">
                  {showStepThree && (
                    <ThirdStepCargoAvailabilityForm
                      model={modelName}
                      availableDecks={availableDecks}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <div>
            <p>* Los datos de ocupación tienen un desfase de 10 minutos.</p>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

const setInitialValues = ({ cargoAvailability }) => {
  return {
    cargoAvailability: {
      ...cargoAvailability
    }
  };
};

const handleSubmit = (values, { props }) => {
  const paramsToSend = snakecaseKeys({
    cargoAvailability: { ...values.cargoAvailability }
  });
  const { formRequest, onHide } = props;

  formRequest(paramsToSend);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(CargoAvailabilityForm);
