import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { IcoMoon } from '../../../components';
import Slides from '../../../components/Utils/Slides';
import { indexSlidesRequest } from '../../../requests/slides';

const FirstSection = ({
  title,
  subtitle,
  dontShowCargo = false,
  slideType = 'principal_banner'
}) => {
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = localStorage.getItem('language') || 'es';
  const { settings } = useSelector(state => state.utils);
  const [bookMyTripText, setBookMyTripText] = useState(undefined);
  const [slideTitle, setSlideTitle] = useState('');
  const [bookMyTripUrl, setBookMyTripTextUrl] = useState(undefined);
  const [slideSubtitle, setSlideSubtitle] = useState('');
  const [finishedFirstFetch, setFinishedFirstFetch] = useState(false);
  const [noSlideFound, setNoSlideFound] = useState(false);

  const updateSlideTextValues = () => {
    if (slides.length !== 0 && !noSlideFound) {
      const {
        heroTitleEs,
        heroTitleEn,
        heroSubtitleEs,
        heroSubtitleEn,
        heroButtonTextEs,
        heroButtonTextEn
      } = slides[0];
      setSlideTitle(language === 'en' ? heroTitleEn : heroTitleEs);
      setSlideSubtitle(language === 'en' ? heroSubtitleEn : heroSubtitleEs);
      const buttonText =
        language === 'en' ? heroButtonTextEn : heroButtonTextEs;
      const buttoneTextValue =
        buttonText !== '' && buttonText !== undefined && buttonText !== null
          ? buttonText
          : t('tourism.landing.firstSection.bookTripTitle');
      setBookMyTripText(buttoneTextValue);
    }
    if (slides.length === 0 && !noSlideFound && finishedFirstFetch) {
      setBookMyTripText(t('tourism.landing.firstSection.bookTripTitle'));
      setNoSlideFound(true);
    }
  };

  const updateSettingsTextValues = () => {
    if (noSlideFound && finishedFirstFetch) {
      const buttonText =
        language === 'en' ? 'landingHeroNeedCargoEn' : 'landingHeroNeedCargoEs';
      const buttoneTextValue =
        settings[buttonText] ||
        t('tourism.landing.firstSection.needCargoButton');
      setBookMyTripText(buttoneTextValue);
    }
  };

  const updateBookMyTripUrlValue = () => {
    const buttonUrl =
      language === 'en' ? 'ticketReservationUrlEn' : 'ticketReservationUrlEs';
    const buttonUrlValue = settings[buttonUrl];
    setBookMyTripTextUrl(buttonUrlValue);
  };

  const requestDefaultSlide = () => {
    if (noSlideFound && finishedFirstFetch) {
      indexSlidesRequest({
        dispatch,
        params: {
          actives: true,
          sort_column: 'position',
          sort_direction: 'asc',
          display_length: 5,
          slide_type: 'principal_banner'
        },
        successCallback: result => {
          setSlides(camelCaseRecursive(result.data.data));
        }
      });
    }
  };

  const slidesRequest = () => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: 5,
        slide_type: slideType
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
        setFinishedFirstFetch(true);
      }
    });
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  useEffect(slidesRequest, []);
  useEffect(updateSlideTextValues, [language, slides, finishedFirstFetch]);
  useEffect(updateSettingsTextValues, [settings]);
  useEffect(updateBookMyTripUrlValue, [settings, language]);
  useEffect(requestDefaultSlide, [noSlideFound, finishedFirstFetch]);

  return (
    <Row className="hero" style={{ overflow: 'hidden' }}>
      <Slides items={slides} />
      <Col sm={12} className="align-self-center container-hero__content">
        <Row className="align-items-center justify-content-center">
          <Col style={{ marginTop: '16rem' }}>
            <h1 className="title-landing">
              {slideTitle !== '' &&
              slideTitle !== undefined &&
              slideTitle !== null
                ? slideTitle
                : title}
            </h1>
            {((slideSubtitle !== '' &&
              slideSubtitle !== undefined &&
              slideSubtitle !== null) ||
              subtitle) && (
              <h6
                style={{ textTransform: 'none' }}
                className="text-white text-justify text-center"
              >
                {slideSubtitle !== '' &&
                slideSubtitle !== undefined &&
                slideSubtitle !== null
                  ? slideSubtitle
                  : subtitle}
              </h6>
            )}
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center mt-5">
          {!dontShowCargo && (
            <>
              <Col md={5} xl={4} className="mt-5 d-flex justify-content-center">
                <Button
                  type="submit"
                  variant="primary"
                  className="d-flex justify-content-center align-items-center p-2 w-75"
                  href={bookMyTripUrl}
                >
                  {bookMyTripText}
                  <div className="box-icon-calendar">
                    <IcoMoon icon="calendar" size={20} />
                  </div>
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default FirstSection;
